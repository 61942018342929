import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import { Box, TextField, FormControl } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { colorTheme } from "../../assets/theme";
import { ReactComponent as SaveIcon } from '../../assets/images/icons/save-icon.svg';
import SecondaryButton from "../buttons/secondary-button";
import HeaderDialog from './header-dialog';
import makeStyles from "@material-ui/core/styles/makeStyles";

// Make style for AddAnswerDialog
const useStyles = makeStyles(() => ({
    dialog: {
        '& .MuiDialog-paper': {
            width: '320px',
        },
    },
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',

        '& .MuiFormControl-root': {
            width: '100%',

            '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: colorTheme.theme.colors.redWarning,
            },

            '& .MuiFormHelperText-root.Mui-error': {
                color: colorTheme.theme.colors.redWarning,
            },
        },
    },
    textField: {
        '& .MuiOutlinedInput-root': {
            padding: '0px',
            fontSize: '15px',
            lineHeight: '20px',
            '& input': {
                padding: '12px 14.5px',
                color: colorTheme.theme.text.mediumGrey,
                height: 'initial',
            },
            '& fieldset': {
                borderColor: colorTheme.theme.lines.lightGrey,
                transition: 'border-color 0.3s',
            },
            '&:hover fieldset': {
                borderColor: colorTheme.theme.text.mediumGrey,
            },
            '&.Mui-focused fieldset': {
                borderColor: 'var(--colors-highlight)',
                borderWidth: '1px',
            },
        },
        '& .MuiInputBase-input::placeholder': {
            color: colorTheme.theme.text.lightGrey,
        },
        '& .MuiFormLabel-root.MuiInputLabel-root': {
            transform: 'translate(14.5px, 12px) scale(1)', // Placeholder stays in place
            fontSize: '15px',
            lineHeight: '20px',
            textTransform: 'none',

            '&.Mui-focused': {
                color: 'var(--colors-highlight)',
                
                '&.MuiInputLabel-outlined': {
                    transform: 'translate(14px, -7.5px) scale(0.75)',
                },
            },
            '&.MuiInputLabel-shrink': {
                transform: 'translate(14px, -7.5px) scale(0.75)',
            },
            '& .MuiFormLabel-asterisk': {
                color: colorTheme.theme.colors.redWarning,
            },
        },
    },
}));

// AddAnswerDialog component for dialog
const AddAnswerDialog = ({ open, handleClose, template, newItem, handleItemChange, handleNewItemAdd, isInErrorState, canSubmit }) => {
    const { t } = useTranslation(); // Translation
    const [saveClicked, setSaveClicked] = useState(false); // Save clicked state
    const classes = useStyles(); // Call useStyles

    // Handle save button
    const handleSave = () => {
        setSaveClicked(true);
        if (canSubmit()) {
            handleNewItemAdd();
        }
    };

    // Reset saveClicked state when dialog is closed
    useEffect(() => {
        setSaveClicked(false);
    }, [open]);

    return (
        <Dialog open={open} onClose={handleClose} className={classes.dialog}
            BackdropProps={{
                style: {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    backdropFilter: 'blur(2.5px)',
                },
            }}
        >
            <HeaderDialog handleClose={handleClose} title={t('addAnswer')} />
            <DialogContent style={{ padding: '20px 20px 0px' }}>
                <FormControl component="fieldset" className='new-item-form' classes={{ root: classes.form }}>
                    {
                        template && template.item_fields &&
                        template.item_fields.map((field, index) => {
                            return (
                                <Box key={index} className='new-item-input'>
                                    <TextField
                                        required={isInErrorState(field)}
                                        classes={{ root: classes.textField }}
                                        id={`input-${field}`}
                                        label={field}
                                        value={newItem && newItem[field] ? newItem[field] : ''}
                                        onChange={e => handleItemChange(e, field)}
                                        variant="outlined"
                                        error={saveClicked && isInErrorState(field)}
                                        helperText={saveClicked && isInErrorState(field) ? t("required") : ''}
                                    />
                                </Box>
                            )
                        })
                    }
                </FormControl>
            </DialogContent>
            <DialogActions style={{ padding: '20px', display: 'flex', gap: '16px' }}>
                <SecondaryButton
                    text="Cancel"
                    backgroundColor="#FFFFFF"
                    textColor={colorTheme.theme.text.lightGrey}
                    borderColor={colorTheme.theme.text.lightGrey}
                    onClick={handleClose} />
                <SecondaryButton
                    text="Save"
                    backgroundColor="var(--colors-highlight)"
                    textColor="#FFFFFF"
                    borderColor="var(--colors-highlight)"
                    Icon={SaveIcon}
                    onClick={handleSave} />
            </DialogActions>
        </Dialog>
    );
};

export default AddAnswerDialog;