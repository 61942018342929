import React, { useEffect, useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from '@material-ui/core';
import { ReactComponent as AlertIcon } from '../assets/images/alert-icon.svg';
import { ReactComponent as InfoIcon } from '../assets/images/icons/info-icon.svg';
import { colorTheme } from '../assets/theme';

// Make styles for the component
const useStyles = makeStyles({
    root: (props) => ({
        marginTop: '16px',
        maxWidth: 'fit-content !important',
        ...props.customStyle,
    }),
    alert: (props) => ({
        backgroundColor: props.type === 'error' ? '#E23D321A' : '#6CADE733',
        color: colorTheme.theme.text.darkGrey,
        borderRadius: '3px',
        padding: '11.5px 10px',
        display: 'flex',
        alignItems: 'center',
    }),
    icon: {
        color: '#d32f2f',
        padding: '0',
    },
    message: {
        padding: '0',
    },
});

// Alert component
function AlertComponent(props) {
    const { id, text, type, customStyle } = props; // Get values from props
    const classes = useStyles({ type, customStyle }); // Get styles from useStyles
    const [open, setOpen] = useState(true); // Set state for alert open

    useEffect(() => {
    }, [id, text]);

    // Get icon based on type
    const getIcon = () => {
        switch (type) {
            case 'error':
                return <AlertIcon />;
            case 'info':
                return <InfoIcon />;
            default:
                return <AlertIcon />;
        }
    }

    // Function to parse text and add bold to specified parts
    const parseText = (text) => {
        const parts = text.split(/(\*\*.*?\*\*)/g).filter(Boolean); // Split text by bold markers
        return parts.map((part, index) => {
            if (part.startsWith('**') && part.endsWith('**')) {
                return <span key={index} style={{ fontWeight: '500' }}>{part.slice(2, -2)}</span>;
            }
            return part;
        });
    }

    // severity: error, warning, info, success
    return (
        <Box className={`${classes.root} alert-container`}>
            <Collapse in={open}>
                <Alert
                    severity={type ? type : 'error'}
                    classes={{
                        root: classes.alert,
                        icon: classes.icon,
                        message: classes.message,
                    }}
                    icon={getIcon()}
                    variant="filled"
                >
                    <Typography style={{ fontSize: '15px' }}>
                        {parseText(text)}
                    </Typography>
                </Alert>
            </Collapse>
        </Box>
    );
}

export default AlertComponent;
