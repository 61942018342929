import React from 'react';
import './single-line-text-question.scss';
import useSingleLineTextQuestion from '../../hooks/questions/use-single-line-text-question';
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import AlertComponent from "../../input-components/alert-component";
import TextButton from "../../components/buttons/text-button";
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { getStyle } from "../styleUtil"
import { colorTheme } from '../../assets/theme';
import _ from 'lodash';

// Styles for component
const useStyles = makeStyles((theme) => ({
    root: {
        // '& > *': {
        //     marginBottom: '10px',
        // },
    },
    textField: {
        '& .MuiOutlinedInput-root': {
            padding: '0px',
            fontSize: '15px',
            lineHeight: '20px',
            '& input': {
                padding: '12px 14.5px',
                color: colorTheme.theme.text.mediumGrey,
                height: 'initial',
            },
            '& fieldset': {
                borderColor: colorTheme.theme.lines.lightGrey,
                transition: 'border-color 0.3s',
            },
            '&:hover fieldset': {
                borderColor: colorTheme.theme.text.mediumGrey,
            },
            '&.Mui-focused fieldset': {
                borderColor: 'var(--colors-highlight)',
                borderWidth: '1px',
            },
        },
        '& .MuiInputBase-input::placeholder': {
            color: colorTheme.theme.text.lightGrey,
        },
        '& .MuiFormLabel-root.MuiInputLabel-root': {
            transform: 'translate(14.5px, 12px) scale(1)', // Placeholder stays in place
            fontSize: '15px',
            lineHeight: '20px',
            textTransform: 'none',

            '&.Mui-focused': {
                display: 'none',
            }
        },
    },
}));

// SingleLineTextQuestion component
function SingleLineTextQuestion(props) {
    const { template } = props; // Get template from props
    const { t } = useTranslation(); // Translation
    const classes = useStyles(); // Get styles

    const {
        answer,
        minError,
        requiredError,
        language,
        hasAnswer,
        handleTextBoxChange,
        handleTextBoxAdd,
        handleTextBoxRemove,
    } = useSingleLineTextQuestion(template); // Get necessary variables and functions from custom hook

    return (
        <Box className='single-line-text-question-container'>
            <form className={classes.root} noValidate autoComplete="off" style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                {
                    answer.map((item, index) => {
                        return (
                            <Box key={index} className='text-box' style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <TextField
                                    className={classes.textField}
                                    InputLabelProps={{ shrink: false }}
                                    label={item ? null : (template.item_placeholder_text ? template.item_placeholder_text : t("answer"))}
                                    variant="outlined"
                                    value={item}
                                    inputProps={{ maxLength: template.max_characters }}
                                    onChange={e => handleTextBoxChange(e, index)}
                                />
                                {
                                    template.max_characters &&
                                    <Typography className='items-left' style={{ color: colorTheme.theme.text.mediumGrey, fontSize: '14px', lineHeight: '20px' }}>{t("charactersLeft")}: <span style={{ color: (template.max_characters - item.length) > 0 ? colorTheme.theme.text.green : colorTheme.theme.text.red, fontWeight: '600' }}>{template.max_characters - item.length}</span></Typography>
                                }
                                {/* {
                                    (!template.items_min_number || (index + 1) > template.items_min_number) &&
                                    <DeleteIcon className='remove-item' onClick={() => handleTextBoxRemove(index)} />
                                } */}
                            </Box>
                        )
                    })
                }
            </form>
            {
                template && answer && (template.items_min_number < template.items_max_number || !template.items_max_number) && answer.length < template.items_max_number &&
                <Box className='button-and-in' style={{ ...getStyle('button-and-in', language), marginTop: '16px' }}>
                    <TextButton onClick={() => handleTextBoxAdd()} title={template.item_add_text ? template.item_add_text : t("addOtherAnswer")} />
                </Box>
            }
            {
                minError &&
                <AlertComponent text={`${t("answerInputInfoPt1")} ${template.items_min_number} ${t("answerInputInfoPt2Text")}`} type='info' />
            }
            {
                requiredError && template.required && !hasAnswer(answer) &&
                <AlertComponent text={t("required")} type={'error'} />
            }
        </Box>
    );
}

export default SingleLineTextQuestion;
